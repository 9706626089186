import { $count, $isarray, $isfunction, $isobject, $ok, $strings } from "foundation-ts/commons";
import { $uuid } from "foundation-ts/crypto";
import { $trim } from "foundation-ts/strings";
import { TSError } from "foundation-ts/tserrors";
import { Nullable, TSDictionary, UUID } from "foundation-ts/types";
import { $insp, $logheader, $logterm } from "foundation-ts/utils";

export type TSStyleValidator<T> = (styleIdentifier:UUID, path:string[], value:T) => boolean ;
export type TSStyleDynamicContext = (styleIdentifier:UUID) => Nullable<string> ;
export type TSStylePathValidator = (styleIdentifier:UUID, path:string) => boolean ;

export interface TSStyleParameters<T> {
    jsonSource:object ;
    roots:TSDictionary<T>,
    valueValidator:TSStyleValidator<T> ;

    contexts?:Nullable<string[]> ;
    states?:Nullable<string[]> ;
    global?:Nullable<string> ;
    local?:Nullable<string> ;
    stateIsLocal?:Nullable<boolean> ;
    debug?:Nullable<boolean> ;
    pathValidator?:Nullable<TSStylePathValidator> ;
}

enum _CompType {
    Top,
    Global,
    Root,
    State,
    Context
 } ;

export class TSStyleManager<T> {
    public static readonly defaultContext = "_$_" ;

    private static __pathRegex = /^[a-z\.]*$/ ;
    private static __elementRegex = /^[a-z]*$/ ;

    public readonly identifier = $uuid() ;

    private _global:string ;
    private _local:string ;
    private _globalStart:string ;
    private _contextsCache = new Map<string, Map<string,T>>() ;
    private _componentType = new Map<string, _CompType>() ;
    private _styles:TSDictionary<TSDictionary> = {} ;
    private _validator:TSStyleValidator<T> ;
    private _pathValidator?:Nullable<TSStylePathValidator> ;
    private _currentContext:string = TSStyleManager.defaultContext ;
    private _debug:boolean ;
    private _stateIsLocal:boolean ;

    private isRoot(s:Nullable<string>):boolean      { return $ok(s) && this._componentType.get(s!) === _CompType.Root ; }
    private isState(s:Nullable<string>):boolean     { return $ok(s) && this._componentType.get(s!) === _CompType.State ; }
    private isContext(s:Nullable<string>):boolean   { return $ok(s) && this._componentType.get(s!) === _CompType.Context ; }
    private isGlobal(s:Nullable<string>):boolean    { return $ok(s) && this._componentType.get(s!) === _CompType.Global ; }
    private isLastComponent(s:Nullable<string>):boolean {
        if ($ok(s)) {
            const t = this._componentType.get(s!) ;
            return t === _CompType.Root || t === _CompType.Context ;
        }
        return false ;
    }
    
    public constructor(params:TSStyleParameters<T>) {
        this._debug = !!params.debug ;

        if ($ok(params.global)) {
            if (!this._validateElement("", params.global!, "global path component")) {
                this._throw(`"${params.global}" is not accepted has global keyword`) ;
            } 
            this._global = params.global! ;
        }
        else { this._global = "global" ; }
        this._globalStart = this._global + '.' ;

        if ($ok(params.local)) {
            if (!this._validateElement("", params.local!, "local path component")) {
                this._throw(`"${params.local}" is not accepted has local keyword`) ;
            } 
            this._local = params.local! ;
        }
        else { this._local = "local" ; }


        if ($isfunction(params.pathValidator)) { this._pathValidator = params.pathValidator! ; }
        else if (this._debug) {
            this._warning("You are about to create an object without any path validator function")
        }

        if ($isfunction(params.valueValidator)) { this._validator = params.valueValidator! }
        else { this._throw("new object has no value validator function defined") ; }

        this._stateIsLocal = !!params.stateIsLocal ;
        
        this._constructRoots(params.roots) ;
        this._constructContexts(params.contexts) ;
        this._constructStates(params.states)
        this._interpretJsonSource(params.jsonSource) ;
    }
    
    public get currentContext():string { return this._currentContext ; }
    public set currentContext(context:string) {
        if (this.isContext(context)) { this._currentContext = context ; }
        else if (this._debug) {
            this._error(`Trying to set unknown context "&o${context}&g" to current context`)
        } 
    }

    public logStyles():void {
        $logterm('') ;
        $logheader(`${this.constructor.name} defined styles`) ;
        $logterm('&0&w'+$insp(this._styles)) ;
        $logterm('&x==========================================&0') ;
    }

    public logComponents():void {
        $logterm('') ;
        $logheader(`${this.constructor.name} defined components`) ;
        $logterm('&0&w'+$insp(this._componentType)) ;
        $logterm('&x==========================================&0') ;
    }

    public style(path:string):T|null { 
        const d = this._debug ;
        if (d) { $logterm('') ; }

        path = $trim(path).toLowerCase() ;
        if (path.startsWith(this._globalStart)) { path = path.slice(this._globalStart.length) ; }
        if (!path.length) { return this._error("Ask for style with empty path") ; }
        if (d) { _debug(`looking for style("&w${path}&g")`) ; }

        let cache = this._contextsCache.get(this._currentContext) ;
        const cachedValue = cache!.get(path) ;
        if ($ok(cachedValue)) {
            if (d) { _debug(`will return cached value "&w${cachedValue}&g"`) ; } 
            return cachedValue! ; 
        }
        if (d) { _debug(`Path "&w${path}&g" is not cached`) ; }

        if (!TSStyleManager.__pathRegex.test(path)) { return this._error(`path "&w${path}&o" is malformed`) ; }
        if ($ok(this._pathValidator) && !this._pathValidator!(this.identifier, path)) { return this._error(`path "&w${path}&o" is not valid`) ; }

        return this._style(path, this._currentContext, cache!) ; // no toBeCachedPath at first call
    }

    // ============================ private methods =======================================================================
    private _constructRoots(roots:TSDictionary<T>) {
        const entries = Object.entries(roots) ;
        let rn = 0 ;

        for (let [k, v] of entries) {
            k = this._validateElement("", k, "root key") ;
            if (k === this._global || k === this._local || k === TSStyleManager.defaultContext) {
                this._throw(`root key "${k}" cannot be used`) ;
            }
            else if ($ok(this._validator) && !this._validator!(this.identifier, [k], v)) { 
                this._throw(`found wrong root value ${v} for key "${k}"`) ;
            } 
            if (this.isRoot(k)) {
                this._throw(`root style "${k}" is loaded twice`) ;
            }
            this._componentType.set(k, _CompType.Root) ;
            const rootNode:TSDictionary = {} ;
            rootNode[TSStyleManager.defaultContext] = v ;
            this._styles[k] = rootNode ;
            rn ++
        }
        if (!rn) {
            this._throw("cannot define a new object without any root values") ;
        }
    }

    private _constructContexts(contexts:Nullable<string[]>) {
        if ($count(contexts)) {
            for (let c of contexts!) {
                c = this._validateElement("", c, "context") ;
                if (c === TSStyleManager.defaultContext || c === this._global || c === this._local) {
                    this._throw(`use of context "${c}" is not authorized`) ;
                }
                if (this.isRoot(c)) {
                    this._throw(`context "${c}" is similar to a root style key`) ;
                }
                if (this._contextsCache.has(c)) {
                    this._throw(`context "${c}" is defined twice`) ;
                }
                this._contextsCache.set(c, new Map<string,T>()) ;
                this._componentType.set(c, _CompType.Context) ;
            }
        }
        else if (this._debug) { 
            this._warning("You are about to define a new object without any contexts") ; 
        }
        this._contextsCache.set(TSStyleManager.defaultContext, new Map<string,T>) ;
    }

    private _constructStates(states:Nullable<string[]>) {
        if ($count(states)) {
            for (let s of states!) {
                s = this._validateElement("", s, "state") ;
                const t = this._componentType.get(s) ;
                if (t === _CompType.Root || t === _CompType.Context || s === this._global || s === this._local || s === TSStyleManager.defaultContext) {
                    this._throw(`use of state "${s}" is not authorized`) ;
                }
                if (t === _CompType.State) {
                    this._throw(`state "${s}" is defined twice`) ;
                }
                this._componentType.set(s, _CompType.State) ;
            }

        }
        else if (this._debug) { 
            this._warning("You are about to define a new object without any states") ; 
        }
    }


    private _warning(message:String):null {
        if (!this._debug) { $logterm('') ; } 
        $logterm(`&0&x${this.constructor.name} &cWARNING&x: &w${message}&0&w.&0`) ;
        return null ; 
    }
    
    private _error(message:String):null {
        if (!this._debug) { $logterm('') ; } 
        $logterm(`&0&x${this.constructor.name} &0&R&w ERROR &0&x &e${message}&0&e.&0`) ;
        return null ; 
    }

    private _throw(message:String):never {
        this._error(message) ;
        TSError.throw(`${this.constructor.name} ERROR: ${message}.`)
    }


    private _style(path:string, current:string, cache:Map<string,T>, toBeCachedPath?:string):T|null {
        
        const d = this._debug ;

        function _internalValue(nc:Nullable<TSDictionary>, context:string):Nullable<T> {
            if ($ok(nc)) {
                let vn = nc![context] ;
                if ($ok(vn)) { return vn ; }
                if (context !== TSStyleManager.defaultContext) { 
                    vn = nc![TSStyleManager.defaultContext] ;
                    if ($ok(vn)) { return vn ; }
                }
            }
            return null ;
        }
                
        function _value(k:string, n:Nullable<TSDictionary>, state:string, context:string, localKey:string, stateIsLocal:boolean):Nullable<T> {
            let v:Nullable<T> = undefined ;

            if (d) { _debug(`examinated node = &y${$insp(n,1)}&g with state "&w${state}&g"`) ; }
            if (!$ok(n)) { return undefined ; }

            if (state.length) {
                const sn = n![state] ;
                if ($ok(sn)) {
                    v = _internalValue(sn![k], context) ;
                    if ($ok(v)) { return v ; }
                }
                if (!stateIsLocal) { return null ; }
            }
            
            v = _internalValue(n![k], context) ;
            if ($ok(v)) { return v ; }
            if (!localKey.length) { return null ; }
            const nl = n![localKey] ;
            return $ok(nl) ? _internalValue(nl![k], context) : null ;
        }

        let cachedPath = path ;
        if ($ok(toBeCachedPath)) {
            const cachedValue = cache!.get(path) ;
            if ($ok(cachedValue)) {
                if (d) { _debug(`will return cached value "&w${cachedValue}&g and cache it again under path "&j${toBeCachedPath}&g"`) ; } 
                cache!.set(toBeCachedPath!, cachedValue!) ;     
                return cachedValue! ; 
            }    
            cachedPath = toBeCachedPath! ;
        }

        const components = path.split('.') ;
        let n = $count(components) ;
        if (!n) { return this._error("Ask for style with empty path #2") ; }

        const key = components[--n] ;
        if (!this.isRoot(key)) { return this._error(`wrong root key "&w${key}&o" at the end of path`) ; }

        let state = '' ;
        if (n > 1 && this.isState(components[n-1])) {
            state = components[--n] ;
        }
        // const state = n > 1 && this.isState(components[n-1]) ? components[n-1] : '' ;

        let node:TSDictionary = this._styles ;
        let nodes = [node] ;
        let localKey = this._local ;
        if (d) { _debug("root node = "+$insp(node,0)) ; }

        for (let i = 0 ; i < n ; i++) {
            const c = components[i] ;
            if (c === this._global || this.isLastComponent(c)) { return this._error(`wrong path component "&w${c}&o"`) ; }
            const nextNode = node[c] ;
            if (!nextNode) { localKey = '' ; break ; }
            node = nextNode ;
            nodes.push(node) ;
            if (d) { _debug(`node[${c}] = &y${$insp(node,1)}&g`) ; }
        }
        if (d) { _debug(`asked context is "&w${current}&g"`) ; }

        let v:Nullable<T> = undefined ;
        let ri = nodes.length ;
        
        while (!$ok(v) && ri-- > 0) { 
            v = _value(key, nodes[ri], state, current, localKey, this._stateIsLocal || ri === 0) ;  
            if (d) { _debug(`did return value &p${v}&g`) ; }
            localKey = '' ;
        }

        if (ri === 0 && n > 1 && this.isGlobal(components[1])) {
            const alternatePath = components.slice(1).join('.') ;
            if (d) { _debug(`${$ok(v)?"found only global value":"nothing found"} on path "&o${path}&g", will try alternate path "&j${alternatePath}"&g`) ; }
            const v1 = this._style(components.slice(1).join('.'), current, cache, cachedPath) ;
            if ($ok(v1)) { return v1 ; }
        }

        if ($ok(v)) {
            if (d) { _debug(`will return and cache value &w${v}&g`) ; } 
            cache!.set(cachedPath, v!) ; 
            return v! ; 
        }
        if (d) { _debug(`No style found for path "&w${cachedPath}&g"`) ; }
        return null ;

    }

    private _interpretJsonSource(json:object) {

        if (!$isobject(json) || $isarray(json)) { 
            this._throw(`unspecified JSON or passed JSON variable is not a dictionary`) ; 
        }


        const entries = Object.entries(json) ;
        const others:TSDictionary = {}
        const prefix = 'interpreting JSON,' ;
        let n = 0 ;
 
        // managing "global" entries first
 
        for (let [k, v] of entries) {
            // we ignore undefined values
            if ($ok(v)) {
                k = this._validateElement(prefix, k, "root style key") ;
                if (k === this._global) { 
                    if (!$isobject(v) || $isarray(v)) { 
                        this._throw(`${prefix} value for "${k}" key is not a dictionary.`) ; 
                    }
                    this._loadJSONWithPath(prefix, v as object, [], _CompType.Top) ;
                }
                else if (k === this._local) {
                    this._throw(`${prefix} impossible de use ${k} key as root key value.`) ; 
                }
                else {
                    n++ ;
                    others[k] = v ;
                }
            }
        }
        if (n) { this._loadJSONWithPath(prefix, others, [], _CompType.Top) ; }
    }

    private _loadJSONWithPath(prefix:string, node:object, path:string[], previousType:_CompType|undefined) {
        const entries = Object.entries(node) ;

        for (let [k, v] of entries) {
            if ($ok(v)) {
                k = this._validatePathKey(prefix, path, k) ;
                if (!$ok(this._validator) || this._validator!(this.identifier, $strings(path, k), v)) {
                    const type = this._componentType.get(k) ;
                    if (type !== _CompType.Root && type !== _CompType.Context) {
                        this._throw(`${prefix} wrong last component for ${this._ps(path, k)}`)
                    }
                    if (type === _CompType.Root) {
                        if (previousType === _CompType.Root) {
                            this._throw(`${prefix} ${this._ps(path, k)} ends with two consecutive root components`) ;
                        }
                        this._setValueForPathAndContext([...path, k], TSStyleManager.defaultContext, v) ;
                    }
                    else if (previousType === _CompType.Root) {
                        this._setValueForPathAndContext(path, k, v) ;
                    }
                    else {
                        this._throw(`${prefix} ${this._ps(path, k)} ends with a context and has no previous root component`) ;
                    }
                }
                else if ($isarray(v)) {
                    this._throw(`${prefix} value for path ${this._ps(path, k)} cannot be an array`)
                }
                else if ($isobject(v)) {
                    const type = this._componentType.get(k) ;
                    if (previousType === _CompType.Root) {
                        this._throw(`${prefix} ${this._ps(path, k)} contains a misplaced root component`) ;
                    }
                    else if (previousType === _CompType.Top && !$ok(type)) {
                        this._componentType.set(k, _CompType.Global) ;
                    }
                    this._loadJSONWithPath(prefix, v, [...path, k], type) ;
                }
                else {
                    this._throw(`${prefix} value for path ${this._ps(path, k)} has a wrong type`)
                }
            }
        }
    }

    private _setValueForPathAndContext(path:string[], context:string, value:string) {
        let node:TSDictionary = this._styles ;

        for (let k of path) {
            let nextNode:TSDictionary|undefined = node[k] ;
            if (!nextNode) {
                nextNode = {} ;
                node[k] = nextNode! ; 
            }
            node = nextNode!
        }

        node[context] = value ;
    }

    private _ps(path:string[], s:string):string {
        return $count(path) ? `path "${[path].join('.')}.${s}"` : `key "${s}"` ; 
    }

    private _validatePathKey(prefix:string, path:string[], s:string):string {
        s = $trim(s).toLowerCase() ;
        if (!s.length) { 
            const ps = path.length ? `path ${[path].join('.')}` : `key ${this._global}` ; 
            this._throw(`${prefix} found empty key for ${ps}`) ; 
        }
        if (!TSStyleManager.__elementRegex.test(s)) { 
            this._throw(`${prefix} "${this._ps(path, s)}" contains something else than letters`) ; 
        }
        return s ;
    }

    private _validateElement(prefix:string, s:string, type:string):string {
        s = $trim(s).toLowerCase() ;
        if (!s.length) { 
            this._throw(`${prefix} found unacceptable empty ${type}`) ; 
        }
        if (!TSStyleManager.__elementRegex.test(s)) { 
            this._throw(`${prefix} ${type} "${s}" contains something else than letters`) ; 
        }
        return s ;    
    }
} 

function _debug(message:string) { $logterm('&0&cDebug: &g'+message+'.&0') ; }
