import { extendTheme } from "@chakra-ui/react";
import "@fontsource/roboto/400.css";

import JsonStyle from '../../../static/G1Style.json' ;
import JsonColors from '../../../static/G1Colors.json' ;

import { ErrorField } from "../../style/ErrorField";
import { FormControl } from "../../style/FormControl";
import { FormLabel } from "../../style/FormLabel";
import { G1Style } from "./G1Style";

G1Style.loadJSON(JsonStyle, JsonColors) ;

const theme = extendTheme({
    config: {
        initialColorMode: "system",
        useSystemColorMode: true,
    },
    components: {
        FormLabel: FormLabel,
        FormControl: FormControl,
        ErrorField: ErrorField,
    },
    fonts: {
        body: "roboto",
    },
    colors: JsonColors,
    styles: {
        global: (props) => ({
            html: {
                bg: props.colorMode === "dark" ? "black" : "white",
                height: "100vh",
                overflow: "auto",
            },
            body: {
                bg: props.colorMode === "dark" ? "black" : "white",
                overflow: "hidden",
                // override responsive pour l'ensemble des textes définis comme des paragraphes
                p: {
                    // xs, sm , md, lg, xl
                    fontSize: ["0.75rem", "0.875rem", "1rem", "1.125rem", "1.25rem"],
                },
                h3: {
                    // xs, sm , md, lg, xl
                    fontSize: ["1.35rem", "1.475rem", "1.6rem", "1.725rem", "1.85rem"],
                },
                h1: {
                    // xs, sm , md, lg, xl
                    fontSize: ["2.25rem", "2.375rem", "2.4rem", "2.525rem", "2.65rem"],
                },
            },
        }),
    },
});

export default theme;