export const FormLabel = {
  baseStyle: {
    fontWeight: "light",
    fontSize: [
      "0.75rem",
      "0.875rem",
      "1rem",
      "1.125rem",
      "1.25rem",
      "1.5rem",
      "1.875rem",
      "2.25rem",
      "3rem",
      "3.75rem",
      "4.5rem",
      "6rem",
      "8rem",
    ],
  },
};
