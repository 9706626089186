// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-validation-tsx": () => import("./../../../src/pages/account-validation.tsx" /* webpackChunkName: "component---src-pages-account-validation-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin/[...].tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app/[...].tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-confirm-new-email-tsx": () => import("./../../../src/pages/confirm-new-email.tsx" /* webpackChunkName: "component---src-pages-confirm-new-email-tsx" */),
  "component---src-pages-download-signed-documents-tsx": () => import("./../../../src/pages/download-signed-documents.tsx" /* webpackChunkName: "component---src-pages-download-signed-documents-tsx" */),
  "component---src-pages-external-sign-tsx": () => import("./../../../src/pages/external-sign.tsx" /* webpackChunkName: "component---src-pages-external-sign-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-oauth-callback-tsx": () => import("./../../../src/pages/oauthCallback.tsx" /* webpackChunkName: "component---src-pages-oauth-callback-tsx" */),
  "component---src-pages-password-recovery-tsx": () => import("./../../../src/pages/password-recovery.tsx" /* webpackChunkName: "component---src-pages-password-recovery-tsx" */),
  "component---src-pages-recover-tsx": () => import("./../../../src/pages/recover.tsx" /* webpackChunkName: "component---src-pages-recover-tsx" */),
  "component---src-pages-reinitpwd-tsx": () => import("./../../../src/pages/reinitpwd.tsx" /* webpackChunkName: "component---src-pages-reinitpwd-tsx" */),
  "component---src-pages-result-external-sign-tsx": () => import("./../../../src/pages/result-external-sign.tsx" /* webpackChunkName: "component---src-pages-result-external-sign-tsx" */)
}

