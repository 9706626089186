import { $isstring, $isurl, $length, $ok } from "foundation-ts/commons";
import { $trim } from "foundation-ts/strings";
import { TSError } from "foundation-ts/tserrors";
import { $logterm } from "foundation-ts/utils";

import { TSStyleManager } from "./TSStyleManager";

export class G1Style extends TSStyleManager<string> {
    static __singleton: G1Style|undefined = undefined ;

    static get(stylePath:string): string|null { return G1Style.__singleton ? G1Style.__singleton.style(stylePath) : '' ; }

    static loadJSON(styles: object, colors: object) {
        if (G1Style.__singleton) {
            TSError.throw('G1Style already loaded') ;
        }

        G1Style.__singleton = new G1Style({
            jsonSource: styles,
            roots: {
                "write":        "black",
                "bg":           "white",
                "icon":         "white",
                "border":       "gray",
                "placeholder":  "gray",
                "spacer":       "white",
                "iconurl":      "http://placehold.it/150x40.png"    
            },
            contexts: ["light", "dark"],
            states: ["hover", "select"],
            debug: false,
            // @ts-ignore
            valueValidator:(id:UUID, path:string[], s:string) => {
                if ($isstring(s) && $length(s) > 0 && $trim(s) === s) {
                    if (path.findIndex(value => value === "iconurl") !== -1) {
                        return $isurl(s) ;
                    } else {
                        const values = s.split('.') ;
                        if (values.length <= 2) {
                            return values.length === 1 ? $ok(colors[values[0]]) : $ok(colors[values[0]][values[1]]) ;
                        }
                    }
                }

                return false ;
            }
        }) ;
    }

    static logme() { 
        if (G1Style.__singleton) {
            G1Style.__singleton.logStyles() ;
        }
        else {
            console.log('') ;
            $logterm("&R&w  G1Style not defined  &0") ;
        }
    }

    static setCurrentContext(context: "light"|"dark") {
        if (G1Style.__singleton) { G1Style.__singleton!.currentContext = context };
    }
}

export const $g1Color = (path:string): string => {
    return G1Style.get(path) ?? "";
}

export const $g1IconURL = (path:string): string => {
    return G1Style.get(path) ?? "";
}